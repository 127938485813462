@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card-body {
  padding: 4px !important;
}

.ant-tabs-tabpane {
  padding: 2px !important;
}


.site-statistic-demo-card {
  padding: 30px;
  background: #ececec;
}

.site-layout-background {
  padding: 30px;
  background: #ececec;
}

.header {
  background: linear-gradient(135deg,#736cc7 21%,#3190f0 100%,#3190f0 100%) !important; 
  padding : 0px 10px!important;
}

svg { vertical-align: baseline!important; }
.ant-image-preview-img{ display: initial!important;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.ant-form-item-label{
  width: 200px;
  text-align: left;
}


.ant-modal-header{
    background: linear-gradient(135deg,#736cc7 21%,#3190f0 100%,#3190f0 100%) !important;
    border-bottom: 2px dashed #337AB7!important;
    padding: 16px 16px!important;
}

.ant-modal-title{
  color: #FFF!important;
}

.ant-modal .ant-modal-content {
  padding : 0px!important;
}

.ant-modal-content .ant-modal-body {
  padding-right : 15px!important;
  padding-left : 15px!important;
}

.ant-modal-content .ant-modal-footer {
  padding-bottom : 5px!important;
  padding-right : 5px!important;
}

.ant-form-item {
  margin-bottom: 5px!important;
}

.ant-layout-sider-light{
  border-radius: 15px;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #fbfbfb;
}

.ant-menu-sub{
  background: #ffffff;
}

.ant-menu-item-selected{
  background-color : #736dc530;
  color: #1677ff;
}

.ant-spin-text { color: #736cc7; }


.content-cards.safe-card {
  background-color: #35c7bd;
  background-image: linear-gradient(115deg, #35c7bd, #4ee2d8);
}

.content-cards.bank-card {
  background-color: #51d8ff;
  background-image: linear-gradient(294deg, #51d8ff, #5cdec7);
}
.content-cards.status-card {
  background-color: #8ad2f8;
  background-image: linear-gradient(115deg, #8ad2f8, #c1b8ff);
}

.content-cards.safe-card:after {
  width: 144px;
  height: 141px;
  background: url(assets/img/case-big.svg) no-repeat 0 0;
}

.content-cards.bank-card:after {
  width: 159px;
  height: 158px;
  background: url(assets/img/bank.svg) no-repeat 0 0;
}

.content-cards.status-card:after {
  width: 146px;
  height: 148px;
  background: url(assets/img/wallet.svg) no-repeat 0 0;
}

.content-cards:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
}

.content-cards > .text {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
  color: #fff;
}


.content-cards > .number {
  font-size: 50px;
  font-weight: bold;
  line-height: 1.35;
  color: #fff;
}

.ant-table-wrapper .ant-table.ant-table-small {
  font-size: 12px;
}

.draggable {
  user-select: none;
  background-color: white;
  margin: 20px;
  padding: 20px;
  box-shadow: 0px 3px 14px 1px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease-in-out;
}

.draggable:hover {
  transform: scale(1.01);
}

.draggable-item {
  user-select: none;
  padding-left: 5px;
  transition: all 0.2s ease-in-out;
}

.draggable-item:hover {
  padding-left: 5px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1);
  transform: scale(1.01);
}

.icon {
  margin-right: 12px;
  opacity: 0.3;
  cursor: grab;
}

.grabbable {
  cursor: grab;
}

.dragLine {
  border-bottom: dashed 2px rgba(245, 75, 53, 0.445) !important;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

@tailwind base;
@tailwind components;
@tailwind utilities;